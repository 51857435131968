import React from 'react'
import {
  Breadcrumbs,
  Education,
  ServiceContact,
  Hero,
  Page,
} from '@components'

const meta = {
  title: 'Edukacija | Protupožarna zaštita - FSB d.o.o.',
  description: 'Tvrtka FSB d.o.o. radi sprječavanja neželjenih događaja koji se odnose na montažu pasivne protupožarne zaštite osmislila je edukaciju za radnike monterskih tvrtki, projektante i nadzorne inženjere',
  image: require('@assets/images/edu.jpg'),
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/usluge', name: 'Usluge'},
        { link: '/usluge/edukacija', name: 'Edukacija'},
      ]}
    />
    <Hero
      title="Usluga"
      subtitle="Edukacija"
      description={meta.description}
    />
    <Education />
    <ServiceContact id="edukacija" />
  </Page>
)

export default Homepage